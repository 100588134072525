<template>
  <v-container class="py-0">
    <!-- Card Central de Notificacoes -->
    <v-progress-linear
      v-if="showLoading == true"
      indeterminate
      color="primary"
    ></v-progress-linear>

    <v-card outlined class="mx-auto mb-4" max-width="1520">
      <!-- Título do card -->
      <v-row
        no-gutters
        class="px-5 py-3"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
      >
        <h2 style="font-size: 1.5rem" class="font-weight-regular">
          Central de Notificações
        </h2>
        <v-spacer></v-spacer>
      </v-row>
      <v-form ref="form" class="mt-4" v-model="validForm">
        <!-- Título da notificação -->
        <v-row class="px-4">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="notificationTitle"
              label="Título da notificação"
              filled
              required
              :rules="[ruleRequired]"
            ></v-text-field>
          </v-col>

          <!-- Tipo da notificação -->
          <v-col cols="12" sm="3">
            <v-select
              v-model="notificationType"
              :items="notificationTypeItems"
              filled
              label="Tipo"
              required
              :rules="[ruleRequired]"
            ></v-select>
          </v-col>

          <!-- Empresas -->
          <v-col cols="12" sm="5">
            <v-autocomplete
              v-model="companiesSelection"
              :items="companies"
              item-text="name"
              item-value="key"
              filled
              dense
              chips
              multiple
              small-chips
              deletable-chips
              label="Empresas"
              :required="!allCompaniesCheckbox"
            ></v-autocomplete>
            <v-col cols="12" sm="12" class="py-0 checkbox--all-users">
              <v-checkbox
                v-model="allCompaniesCheckbox"
                :label="'Todas as empresas'"
                class="my-0"
                :required="!companiesSelection"
              ></v-checkbox>
            </v-col>
          </v-col>
        </v-row>

        <!-- Mensagem da notificação -->
        <v-row class="px-4">
          <v-col cols="12" sm="7">
            <v-textarea
              filled
              v-model="notificationMessage"
              name="notificationMessage"
              label="Mensagem"
              value=""
              :rules="[ruleRequired]"
            >
            </v-textarea>
          </v-col>

          <v-col cols="12" sm="5">
            <!-- Foto da notificação -->
            <v-text-field
              v-model="notificationPhotoURL"
              label="Foto (url)"
              filled
            ></v-text-field>

            <!-- Link externo de conteúdo -->
            <v-text-field
              v-model="notificationContentLink"
              label="Conteúdo 'Saiba mais' (url)"
              filled
            ></v-text-field>

            <!-- Date picker notification -->
            <v-datetime-picker
              label="Data e hora de envio"
              v-model="sendDate"
              :timePickerProps="{ format: '24hr' }"
            >
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
        <v-card-actions class="btn--card-actions">
          <v-btn
            class="ma-3 text-none text-body-1 font-weight-medium"
            color="grey darken-2"
            @click="reset"
            elevation="0"
            text
            large
          >
            Resetar
          </v-btn>
          <v-btn
            class="ma-3 text-none text-body-1 px-4 font-weight-medium"
            color="secondary"
            elevation="0"
            large
            :disabled="!validForm"
            @click="validate"
          >
            <v-icon v-text="'mdi-send'" dark left />
            Enviar notificação
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <!-- Card log de envios -->
    <v-card outlined class="mx-auto my-10" max-width="1520">
      <!-- Título do card -->
      <v-row no-gutters class="px-5 py-3">
        <h2 style="font-size: 1.5rem" class="font-weight-regular">
          Notificações enviadas
        </h2>
        <v-spacer></v-spacer>
      </v-row>
      <v-divider />
      <v-text-field
        v-model="logSearch"
        prepend-inner-icon="mdi-magnify"
        label="Pesquisar"
        class="px-2"
        single-line
        hide-details
        solo
        flat
      ></v-text-field>
      <v-divider />
      <v-data-table
        :headers="headers"
        :items="notificationsGroups"
        :search="logSearch"
        :items-per-page="itemsPerPage"
        :footer-props="{ itemsPerPageOptions: itemsOptions }"
        :height="tableHeight"
      >
        <template v-slot:[`item.domains`]="{ item }">
          <v-tooltip
            bottom
            max-width="200"
            v-for="customer in item.domains"
            :key="customer.id_google"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                class="font-weight-medium my-1 mr-1"
                @click.stop="
                  $router.push({
                    path: '/partner/customer',
                    query: { domain: customer.main_domain, key: customer.key },
                  })
                "
                v-bind="attrs"
                v-on="on"
              >
                {{ customer.name }} ({{ customer.main_domain }})
              </v-chip>
            </template>
            <span class="caption">
              Acessar perfil do cliente no Conecta Partners
            </span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.notification_type`]="{ item }">
          {{ notificationValues[item.notification_type] }}
        </template>
        <!-- ACAO DE DELETAR -->
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
            icon
            color="error"
            @click="
              (showDialogDeleteItem = true), (notificationToDelete = item.key)
            "
          >
            <v-icon v-text="'mdi-trash-can'"
          /></v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showDialogDeleteItem" width="500">
      <v-card>
        <h2 class="title py-3 px-6">Confirmar exclusão</h2>
        <v-card-text>Você realmente deseja excluir esse envio?</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showDialogDeleteItem = false"
            >Cancelar</v-btn
          >
          <v-btn color="#311F50" text @click="removeNotification"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { paging, tableHeight } from "@/helpers/variables/tablesDefault";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      tableHeight,
      loading: false,
      notificationTitle: null,
      notificationMessage: null,
      notificationType: null,
      notificationTypeItems: ["Notificação", "Janela"],
      notificationTypes: {
        Notificação: "bell",
        Email: "email",
        Dialog: "dialog",
      },
      notificationPhotoURL: null,
      notificationContentLink: null,
      companiesSelection: null,
      companies: null,
      allCompaniesCheckbox: null,
      sendDate: null,
      logSearch: "",
      headers: [
        { text: "Domínios", value: "domains", width: 420 },
        { text: "Título", value: "title" },
        { text: "Tipo", value: "notification_type" },
        { text: "Data agendada", value: "schedule_at" },
        { text: "Data de criação", value: "created_at" },
        { text: "Ações", value: "action" },
      ],
      showDialogDeleteItem: false,
      notificationToDelete: null,
      datetime: null,
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      validForm: true,
    };
  },

  beforeMount() {
    this.getCompanies();
    this.getNotificationsGroups();
  },

  computed: {
    ...mapGetters(["token", "loadingNotifications", "notificationsGroups"]),
    showLoading() {
      return this.loading || this.loadingNotifications;
    },
    notificationTypeLabel() {
      return this.notificationTypes[this.notificationType];
    },
    notificationValues() {
      let values = {};
      for (let label in this.notificationTypes) {
        values[this.notificationTypes[label]] = label;
      }
      return values;
    },
    companiesKeys() {
      return this.allCompaniesCheckbox
        ? this.companies.map(({ key }) => key)
        : this.companiesSelection;
    },
  },

  methods: {
    ...mapActions(["getNotifications", "getNotificationsGroups"]),
    ...mapMutations(["setSnackBar"]),

    ruleRequired(value) {
      return !!value || "Obrigatório";
    },

    createNotification() {
      const url = process.env.VUE_APP_API_BASE_URL + "/notifications";
      const auth = { headers: { Authorization: "Bearer " + this.token } };
      const params = {
        notification_data: {
          title: this.notificationTitle,
          message: this.notificationMessage,
          photo: this.notificationPhotoURL,
          content_link: this.notificationContentLink,
        },
        companies_urlsafe_keys: this.companiesKeys,
        notification_type: this.notificationTypeLabel,
        schedule_date: this.sendDate,
      };

      this.$axios
        .post(url, params, auth)
        .then(() => {
          this.getNotificationsGroups();
          this.getNotifications();
          this.setSnackBar({
            show: true,
            message: "Notificações foram criadas.",
          });
        })
        .catch((error) => {
          console.error(error);
          this.setSnackBar({
            show: true,
            message: "Não foi possível criar as notificações.",
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    removeNotification() {
      const url = process.env.VUE_APP_API_BASE_URL + "/notifications";
      const config = {
        headers: { Authorization: this.token },
        data: { notification_key: this.notificationToDelete },
      };

      this.$axios
        .delete(url, config)
        .then(() => {
          this.getNotificationsGroups();
          this.getNotifications();
          this.showDialogDeleteItem = false;
          this.notificationToDelete = null;
          this.setSnackBar({
            show: true,
            message: "Notificações foram deletadas.",
          });
        })
        .catch((error) => {
          console.error(error);
          this.setSnackBar({
            show: true,
            message: "Não foi possível deletar as notificações.",
            color: "error",
          });
        });
    },

    getCompanies() {
      if (!this.companies) {
        this.$axios
          .get(process.env.VUE_APP_API_BASE_URL + "/company/list", {
            headers: {
              Authorization: this.token,
            },
          })
          .then((response) => {
            this.companies = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
          });
      }
    },

    getColor(messageStatus) {
      if (messageStatus == "Recebido") return "green";
      else if (messageStatus == "Pendente") return "orange";
      else return "red";
    },

    reset() {
      this.$refs.form.reset();
    },

    validate() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.validForm) {
        this.createNotification();
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.btn--card-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.checkbox--all-users {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>
